import { ElLoading, ElLink } from 'element-plus';
import queryString from 'query-string';
const merge = require('lodash/merge');

/* UTILS */
import { message, notification } from '@/utils';

export default (props) => {
    return merge(
        {
            namespaced: true,
            state: {
                loadingCSV: false,
                table: {
                    data: [],
                    sort: [],
                    total: 0,
                    pageSize: 0,
                    currentPage: 1,
                },
                form: {
                    model: {
                        id: '',
                    },
                },
            },
            mutations: {
                setTableData(state, res) {
                    state.table.data = res.data;
                    state.table.total = res.total;
                    state.table.pageSize = res.pageSize;
                },
                setFormModel(state, data) {
                    state.form.model = data || JSON.parse(JSON.stringify(state.form.modelInit));
                },
                setFormModelInit(state) {
                    state.form.modelInit = JSON.parse(JSON.stringify(state.form.model));
                },
                setFormModelReset(state) {
                    state.form.modelReset = JSON.parse(JSON.stringify(state.form.model));
                },
            },
            actions: {
                async getAll(context, params) {
                    if (params.loading) params.loading = ElLoading.service();

                    const url = () => {
                        const base = '/api/' + props.name;
                        const params = {
                            page: context.state.table.currentPage,
                            sort: context.state.table.sort,
                        };

                        return base + '?' + queryString.stringify(params, { arrayFormat: 'separator' }) + '&' + queryString.stringify(context.state.filter.model);
                    };

                    let res = await fetch(url());

                    if (res.status === 200) {
                        res = await res.json();

                        context.commit('setTableData', res);
                    } else {
                        res = await res.json();
                        console.error(res);
                    }

                    if (params.loading) params.loading.close();
                },
                async getOne(context, params) {
                    if (params.loading) params.loading = ElLoading.service();

                    let res = await fetch('/api/' + props.name + '/' + params.id);

                    if (res.status === 200) {
                        res = await res.json();

                        if ((params.fromApi && context.state.form.model.id === params.id) || !params.fromApi) {
                            context.commit('setFormModel', res.data);
                            context.commit('setFormModelReset');
                        }

                        if (params.afterLoad) params.afterLoad();
                    } else {
                        res = await res.json();
                        console.error(res);
                    }

                    if (params.loading) params.loading.close();
                },
                async delete(context, params) {
                    if (params.loading) params.loading = ElLoading.service();

                    let res = await fetch('/api/' + props.name + '/' + params.id, {
                        method: 'delete',
                    });

                    if (res.status !== 200) {
                        res = await res.json();
                        console.error(res);
                    }

                    if (params.loading) params.loading.close();
                },
                async exportCSV(context, params) {
                    context.state.loadingCSV = true;

                    const url = () => {
                        const base = '/api/' + props.name + '/exportCSV';
                        const params = {
                            sort: context.state.table.sort,
                        };

                        return base + '?' + queryString.stringify(params, { arrayFormat: 'separator' }) + '&' + queryString.stringify(context.state.filter.model);
                    };

                    message('info', 'La exportación se está procesando');

                    let res = await fetch(url());

                    if (res.status === 200) {
                        res = await res.json();

                        const link = () => {
                            window.location = '/api/' + props.name + '/download/' + res.id;
                            noti.close();
                        };

                        const noti = notification('success', 'Exportación finalizada', <ElLink onclick={link}>Descargar archivo</ElLink>);
                    } else {
                        res = await res.json();
                        console.error(res);
                    }

                    context.state.loadingCSV = false;
                },
            },
            getters: {},
        },
        props
    );
};

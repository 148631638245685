import module from './module';
import { ElLoading } from 'element-plus';
import { message, confirm } from '@/utils';

export default module({
	name: 'cuenta',
	state: {
		form: {
			model: {
				nombre: '',
				identificador: '',
				presentacion: '',
				fecha_inicio: '',
				estado: false,
				plan: '',
				cuota: 1,
				dominios: '',
				cuentas_ses: [],
				adjuntos: false,
				pie_pagina: true,
				enmascaramiento: false,
				velocidad: 3,
				punto_enlace: '',
				dominio_validador: '',
				limpieza_rebotados: false,
				limpieza_no_abiertos: false,
				remitente_nombre: '',
				remitente_direccion: '',
				remitente_editable: false,
				respuesta_nombre: '',
				respuesta_direccion: '',
				respuesta_editable: false,
			},
		},
		filter: {
			model: {
				nombre: '',
			},
		},
	},
	actions: {
		createInstance(context, params) {
			confirm('Se creará la instancia <b>' + params.nombre + '</b><br>¿Desea continuar?', async () => {
				params.loading = ElLoading.service();

				let res = await fetch('/api/cuenta/createInstance/' + params.id, {
					method: 'put',
				});

				if (res.status === 200) {
					res = await res.json();
					message('success', res.message);
				} else {
					res = await res.json();
					message('error', res.message);
				}

				params.loading.close();
			});
		},
	},
});

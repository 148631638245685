<template>
	<el-container :class="{ show: show }">
		<el-header>
			<el-icon :size="20" class="lines" @click="drawer = true"><LineHorizontal320Filled /></el-icon>

			<img src="@/assets/img/logo_small.png" />

			<el-menu :router="true" :default-active="path" :ellipsis="false" mode="horizontal">
				<!--
                <el-menu-item index="dashboard">
                    <el-icon v-if="path === 'dashboard'"><Board24Filled /></el-icon>
                    <el-icon v-if="path !== 'dashboard'"><Board24Regular /></el-icon>
                    Dashboard
                </el-menu-item>
                -->
				<el-menu-item index="cuentas">
					<el-icon v-if="path === 'cuentas'"><Mail24Filled /></el-icon>
					<el-icon v-if="path !== 'cuentas'"><Mail24Regular /></el-icon>
					Cuentas
				</el-menu-item>
				<el-menu-item index="configuracion">
					<el-icon v-if="path === 'configuracion'"><Settings24Filled /></el-icon>
					<el-icon v-if="path !== 'configuracion'"><Settings24Regular /></el-icon>
					Configuración
				</el-menu-item>
				<el-menu-item index="utilidades">
					<el-icon v-if="path === 'utilidades'"><Collections24Filled /></el-icon>
					<el-icon v-if="path !== 'utilidades'"><Collections24Regular /></el-icon>
					Utilidades
				</el-menu-item>
				<div style="flex-grow: 1"></div>
				<el-switch v-model="isDark" :active-action-icon="WeatherMoon16Filled" :inactive-action-icon="WeatherSunny16Filled" inline-prompt />
				<el-sub-menu index="sesion">
					<template #title>
						<el-icon><Person24Regular /></el-icon>
						{{ store.state.user.nombre }} {{ store.state.user.apellido }}
					</template>
					<el-menu-item index="" @click="cambiarContrasena">
						<el-icon :size="14"><ChevronRight24Regular /></el-icon>
						Cambiar contraseña
					</el-menu-item>
					<el-divider style="margin: 0"></el-divider>
					<el-menu-item index="usuarios">
						<el-icon :size="14"><ChevronRight24Regular /></el-icon>
						Usuarios
					</el-menu-item>
					<el-divider style="margin: 0"></el-divider>
					<el-menu-item index="" @click="cerrarSesion">
						<el-icon :size="14"><ChevronRight24Regular /></el-icon>
						Cerrar sesión
					</el-menu-item>
				</el-sub-menu>
			</el-menu>
			<el-switch class="switch-xs" v-model="isDark" :active-action-icon="WeatherMoon16Filled" :inactive-action-icon="WeatherSunny16Filled" inline-prompt />
		</el-header>
		<el-main>
			<router-view v-slot="{ Component }">
				<keep-alive>
					<component :is="Component" />
				</keep-alive>
			</router-view>
		</el-main>
		<el-footer>
			<el-text>Crea Mailings&nbsp;&nbsp;&bull;&nbsp;&nbsp;Comunica&nbsp;&nbsp;&bull;&nbsp;&nbsp;Fideliza&nbsp;&nbsp;&bull;&nbsp;&nbsp;Crece</el-text>
		</el-footer>
	</el-container>

	<el-drawer v-model="drawer" direction="ltr" :with-header="false">
		<el-menu :router="true" :default-active="path" @select="drawer = false">
			<el-menu-item index="cuentas">
				<el-icon v-if="path === 'cuentas'"><Mail24Filled /></el-icon>
				<el-icon v-if="path !== 'cuentas'"><Mail24Regular /></el-icon>
				Cuentas
			</el-menu-item>
			<el-menu-item index="utilidades">
				<el-icon v-if="path === 'utilidades'"><Collections24Filled /></el-icon>
				<el-icon v-if="path !== 'utilidades'"><Collections24Regular /></el-icon>
				Utilidades
			</el-menu-item>
			<el-sub-menu index="sesion">
				<template #title>
					<el-icon><Person24Regular /></el-icon>
					{{ store.state.user.nombre }} {{ store.state.user.apellido }}
				</template>
				<el-menu-item index="" @click="cambiarContrasena">
					<el-icon :size="14"><ChevronRight24Regular /></el-icon>
					Cambiar contraseña
				</el-menu-item>
				<el-divider style="margin: 0"></el-divider>
				<el-menu-item index="usuarios">
					<el-icon :size="14"><ChevronRight24Regular /></el-icon>
					Usuarios
				</el-menu-item>
				<el-divider style="margin: 0"></el-divider>
				<el-menu-item index="" @click="cerrarSesion">
					<el-icon :size="14"><ChevronRight24Regular /></el-icon>
					Cerrar sesión
				</el-menu-item>
			</el-sub-menu>
		</el-menu>
	</el-drawer>

	<CambiarContrasena ref="cambiarContrasenaRef"></CambiarContrasena>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ElLoading } from "element-plus";
import { useDark, useWindowSize } from "@vueuse/core";
import { Check, Close } from "@element-plus/icons-vue";
import { confirm, setVH } from "@/utils";
import {
	Board24Regular,
	Board24Filled,
	Mail24Regular,
	Mail24Filled,
	Settings24Regular,
	Settings24Filled,
	Collections24Regular,
	Collections24Filled,
	ChevronRight24Regular,
	Person24Regular,
	WeatherSunny16Filled,
	WeatherMoon16Filled,
	LineHorizontal320Filled,
} from "@vicons/fluent";

import CambiarContrasena from "@/views/CambiarContrasena.vue";

/* refs */
const drawer = ref(false);
const show = ref(false);

/* isDark & path */
const isDark = useDark();
const path = computed(() => useRoute().path.substring(1));

/* viewport */
const store = useStore();
const { width } = useWindowSize();
store.state.viewport.width = width;

/* cambiar contraseña */
const cambiarContrasena = () => {
	cambiarContrasenaRef.value.show();
};

/* cerrar sesion */
const cerrarSesion = () => {
	confirm("¿Desea cerrar sesión?", async () => {
		ElLoading.service();

		await fetch("/logout", {
			method: "post",
		});

		window.location = "/";
	});
};

/* ref */
const cambiarContrasenaRef = ref(null);

/* setVH */
setVH(show);
</script>

import module from "./module";

export default module({
    name: "usuario",
    state: {
        form: {
            model: {
                nombre: "",
                apellido: "",
                email: "",
                usuario: "",
                clave: "",
                activo: false,
            },
        },
        filter: {
            model: {
                nombre: "",
            },
        },
    },
});

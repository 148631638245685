import { createApp } from "vue";
import Index from "./Index.vue";
import router from "@/router";
import store from "@/store";

import ElementPlus from "element-plus";
import es from "element-plus/es/locale/lang/es";
import "element-plus/theme-chalk/index.css";
import "element-plus/theme-chalk/dark/css-vars.css";
import "@/assets/css/index.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";

var app = createApp(Index).use(store).use(router).use(ElementPlus, {
    locale: es,
});

app.mount("#app");

/* LOGOUT */
(function () {
    let timeOut;

    function resetTimer() {
        clearTimeout(timeOut);

        timeOut = setTimeout(async () => {
            await fetch("/api/usuario/logout", {
                method: "post",
            });

            window.location = "/";
        }, 2 * 60 * 60 * 1000);
    }

    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer;
    window.ontouchstart = resetTimer;
    window.onclick = resetTimer;
    window.onkeydown = resetTimer;
    window.addEventListener("scroll", resetTimer, true);
})();

/* WEBSOCKET */
(function () {
    let reconnecting = 0;
    const createSocket = function () {
        const ws = new WebSocket("wss://" + window.location.host);

        ws.onopen = function () {
            reconnecting = 0;
        };

        ws.onmessage = function (payload) {
            const data = payload.data.split("&");

            const params = {
                fromApi: true,
            };

            if (data[1] !== undefined) params.id = Number(data[1]);

            store.dispatch(data[0], params);
        };

        ws.onclose = function () {
            if (++reconnecting < 30) createSocket();
            else window.location = "/";
        };
    };

    createSocket();
})();

/* MODEL INIT */
for (const name in store.state) {
    if (store.state[name].form?.model) {
        store.commit(name + "/setFormModelInit");
    }
}

/* GET DATA */
store.dispatch("data/getAll");

/* TOLOCALSTRING */
Number.prototype.toLocaleString = function () {
    return Intl.NumberFormat("de-DE").format(this);
};

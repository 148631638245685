import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        redirect: (to) => {
            return { path: '/cuentas' };
        },
    },
    {
        path: '/cuentas',
        name: 'cuentas',
        component: () => import('@/views/Cuentas.vue'),
    },
    {
        path: '/configuracion',
        name: 'configuracion',
        component: () => import('@/views/Configuracion.vue'),
    },
    {
        path: '/utilidades',
        name: 'utilidades',
        component: () => import('@/views/Utilidades.vue'),
    },
    {
        path: '/usuarios',
        name: 'usuarios',
        component: () => import('@/views/Usuarios.vue'),
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;

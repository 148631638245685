// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --mailinweb-color-1: rgb(8, 114, 195);
    --mailinweb-color-2: rgb(253, 82, 93);
    --el-menu-active-color: var(--mailinweb-color-2);
    --el-menu-hover-bg-color: transparent;
    --el-menu-hover-text-color: #999;
    --el-mask-color: rgba(0, 0, 0, 0.1);
    --el-overlay-color-lighter: rgba(0, 0, 0, 0.1);
    --el-color-primary: var(--mailinweb-color-1);
}

body {
    margin: 0;
    font-family: 'Roboto';
    overflow: hidden;
}

:not(input):not(textarea) {
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

/* LAYOUT */
.el-container {
    opacity: 0;
    transition: all 0.5s;
    transition-delay: 0.5s;
}
.el-container.show {
    opacity: 1;
}
.el-container .el-header {
    --el-header-padding: 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--el-border-color-light);
}
.el-container .el-main {
    --el-main-padding: 0;
    height: calc((var(--vh, 1vh) * 100) - 100px) !important;
}
.el-container .el-footer {
    --el-footer-padding: 0;
    --el-footer-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid var(--el-border-color-light);
}

/* HEADER */
.el-container .el-header {
    justify-content: space-between;
}
.el-container .el-header .lines {
    margin-left: 20px;
    cursor: pointer;
}
@media (min-width: 700px) {
    .el-container .el-header .lines {
        display: none;
    }
}
.el-container .el-header img {
    -o-object-fit: none;
       object-fit: none;
    width: 180px;
}
@media (min-width: 600px) {
    .el-container .el-header img {
        margin-left: 20px;
    }
}
.el-container .el-header .el-menu {
    flex-grow: 1;
    border-bottom: none;
}
@media (max-width: 699px) {
    .el-container .el-header .el-menu {
        display: none;
    }
}
.el-container .el-header .el-menu .el-switch {
    height: auto;
}
.el-container .el-header .el-menu .el-switch .el-switch__inner {
    margin: 0 10px;
}
.el-container .el-header .el-menu > .el-menu-item.is-active,
.el-container .el-header .el-menu > .el-sub-menu.is-active .el-sub-menu__title {
    border-color: transparent;
}
.el-container .el-header .el-switch.switch-xs {
    margin-right: 20px;
}
@media (min-width: 700px) {
    .el-container .el-header .el-switch.switch-xs {
        display: none;
    }
}

/* EL MENU */
.el-menu {
    border-right: none;
}
.el-menu--horizontal > .el-sub-menu .el-sub-menu__title:hover {
    /* En modo oscuro al hacer hover sobre un submenu se cambia el color de fondo, esto lo deja transparent */
    background-color: transparent;
}

/* MENU */
.menu {
    /* Se utiliza para ordenar los botones y darle un espacio */
    padding: 10px;
    justify-content: space-between;
}
@media (max-width: 599px) {
    .menu-xl {
        display: none;
    }
}
@media (min-width: 600px) {
    .menu-xs {
        display: none;
    }
}
.menu .el-input {
    width: 260px;
}
@media (max-width: 599px) {
    .menu .el-input {
        width: 240px;
    }
}
.menu .el-col-24 {
    flex: 0 0 auto;
}

/* TABLE */
.el-table {
    border-top: 1px solid var(--el-border-color-light);
    height: calc((var(--vh, 1vh) * 100) - 184px) !important;
    --el-table-header-text-color: var(--el-text-color-primary);
}
.el-table .el-tag {
    width: 100%;
}
.el-table th .cell {
    font-weight: 500;
}
.el-table th.el-table__cell {
    background-color: var(--el-fill-color-light) !important;
}
.el-table .sort-caret.ascending {
    border-bottom-color: var(--el-text-color-disabled);
}
.el-table .ascending .sort-caret.ascending {
    border-bottom-color: var(--el-text-color-regular);
}
.el-table .sort-caret.descending {
    border-top-color: var(--el-text-color-disabled);
}
.el-table .descending .sort-caret.descending {
    border-top-color: var(--el-text-color-regular);
}

/* PAGINATION */
.el-pagination {
    padding: 4px 10px;
}

/* DIALOG */
.el-dialog {
    --el-dialog-border-radius: 4px;
    --el-dialog-width: 600px;
    max-width: 90%;
    margin: 5vh auto;
}
.el-dialog .el-dialog__header {
    border-bottom: 1px solid var(--el-border-color);
    padding-bottom: var(--el-dialog-padding-primary);
    margin-right: 0;
}
.el-dialog .el-dialog__body {
    padding: 25px 25px 7px 25px;
}
.el-dialog.tab-padding .el-dialog__body {
    padding: 0 25px;
}
.el-dialog .el-dialog__footer {
    border-top: 1px solid var(--el-border-color);
    padding-top: var(--el-dialog-padding-primary);
}

/* TABS */
.el-tabs {
    --el-tabs-header-height: 50px;
}
.el-tabs__header {
    margin: 0 0 25px;
}
.el-tabs__content {
    margin-bottom: 7px;
}

/* SWITCH */
.el-switch__core .el-switch__inner .is-text {
    padding: 0 5px;
}

/* SELECT */
.el-select {
    width: 100%;
}

/* INPUT NUMBER */
.el-input-number {
    width: 100%;
}

/* DATE EDITOR */
.el-date-editor {
    --el-date-editor-width: 100%;
}

/* MESSAGE BOX */
.el-message-box {
    padding-bottom: 0;
    padding: 20px;
}
.el-message-box__btns {
    padding: 0;
}
.el-message-box__header {
    padding: 0;
}
.el-message-box__status {
    padding-right: 0;
}

/* NOTIFICATION */
.el-notification {
    --el-notification-title-font-size: 15px;
}
.el-notification__title {
    font-weight: 500;
}

/* LINK */
.el-link {
    gap: 5px;
}

/* DRAWER */
.el-drawer {
    width: 230px !important;
}
.el-drawer__body {
    padding: 0;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

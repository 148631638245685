import { ElMessage, ElMessageBox, ElNotification, ElLoading } from 'element-plus';

export const message = (type, message) => {
	return ElMessage({
		type: type,
		message: message,
		grouping: true,
		duration: 5000,
		showClose: true,
		dangerouslyUseHTMLString: true,
	});
};

export const notification = (type, title, message) => {
	return ElNotification({
		type: type,
		title: title,
		message: message,
		duration: 0,
		dangerouslyUseHTMLString: true,
	});
};

export const confirm = (message, onConfirm) => {
	return ElMessageBox.confirm(message, {
		type: 'warning',
		center: true,
		buttonSize: 'default',
		grouping: true,
		showClose: false,
		cancelButtonText: 'Cancelar',
		confirmButtonText: 'Confirmar',
		dangerouslyUseHTMLString: true,
		cancelButtonClass: 'el-button--danger is-plain is-round',
		confirmButtonClass: 'el-button--success is-plain is-round',
	})
		.then(() => {
			onConfirm();
		})
		.catch(() => {});
};

export const setVH = (show) => {
	const resize = () => {
		setTimeout(() => {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);

			show.value = true;
		}, 100);
	};
	resize();

	window.addEventListener('resize', resize);
};

export const submit = (formRef, state, config) => {
	document.querySelector('button').disabled = true;

	formRef.value.validate(async (valid, info) => {
		if (valid) {
			const loading = ElLoading.service();

			const data = new FormData();
			for (const index in state.form.model) {
				let value = state.form.model[index];

				if (value === true) value = 1;
				if (value === false) value = 0;
				if (value === null) value = '';

				if (Array.isArray(value)) {
					for (const i in value) {
						data.append(index + '[]', value[i] instanceof File ? value[i] : value[i] instanceof Object ? JSON.stringify(value[i]) : value[i]);
					}
				} else {
					if (!(index == 'id' && !state.form.model['id'])) data.append(index, value);
				}
			}

			const id = state.form.model.id;
			let res = await fetch('/api/' + config.name + (config.action ? '/' + config.action : '') + (id ? '/' + id : ''), {
				method: id ? 'put' : 'post',
				body: data,
			});

			loading.close();

			if (res.status === 200) {
				res = await res.json();
				config.form.visible = false;
				message('success', res.message);
			} else {
				res = await res.json();
				message('error', res.message);
			}
		} else {
			message('warning', 'Verifique los datos del formulario');
		}

		document.querySelector('button').disabled = false;
	});
};
